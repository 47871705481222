<template>
  <v-dialog v-model="visible" max-width="400">
    <template v-slot:activator="{ on, attrs }">
      <v-btn :icon="!caption" :text="caption != null" :color="color" :small="small"  :x-small="xSmall" 
        v-bind="attrs"
        v-on="on"
      >
        <v-icon v-if="icon" small :x-small="small || xSmall" :class="`${caption != null ? 'mr-2':''}`">fal fa-info-circle</v-icon>
        {{ caption }}
      </v-btn>
    </template>    
    <v-card>  
      <v-card-title class="display-1">{{ title }}</v-card-title>
      <v-card-text class="mt-4 pb-0">
        <VueMarkdown :html="false" >{{ text }}</VueMarkdown>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="visible = false">Close</v-btn>
      </v-card-actions>      
    </v-card>
  </v-dialog>
</template>

<script>
import VueMarkdown from '@/components/VueMarkdown.vue'

export default {
  name: "TooltipDialog",
  props: {
    title: String,
    text: String,
    icon: {
      default: true,
      type: Boolean,
    },
    color: {
      default: 'Blue',
      type: String,
    },
    caption: String,
    small: Boolean,
    xSmall: Boolean,
  },
  components: {
    VueMarkdown,
  },
  data: function() {
    return {
      visible: false,
    };
  },
  mounted() {
  },
  methods: {
    show() {
      this.visible = true;
    },
  },
  computed: {
  },
};
</script>

<style scoped>
</style>