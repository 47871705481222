<template>
  <v-chip outlined color="green" class="">{{ points | points }}</v-chip>
</template>

<script>
export default {
  name: "ActivityScoreChip",
  components: {
  },
  props: {
    points: Number,
  },
  computed: {
  }
};
</script>

<style lang="scss">
</style>