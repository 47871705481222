<template>
  <div class="chart-container" style="position: relative; height:200px; ">
    <canvas ref="chart"></canvas>
    
  </div>
</template>


<script>

import { Chart, BarController, BarElement, LinearScale, TimeScale, Tooltip  } from 'chart.js'
Chart.register(BarController, BarElement, LinearScale, TimeScale, Tooltip );

import { DateTime, Duration, Interval } from 'luxon';
import 'chartjs-adapter-luxon';
//import colors from 'vuetify/lib/util/colors'

export default {
  name: "RaceHistogram",
  components: {
  },
  props: {
    data: Object,
    medianMinute: Number,
    minimal: Boolean,
    defaultColor: {
        type: String,
        default: '#ccc',
    },
    highlightColor: String,
    highlightMinute: Number,
  },
  data() {
    return {
        highlightValue: null,
        medianValue: null,
        chart: null,
    };
  },
  async mounted() {
    this.prepareData();
    if (this.data) {
        this.createChart();
    }
  },
  methods: {
    prepareData() {
        if (this.highlightMinute) {
            this.highlightValue = this.minutesToDateTime(this.highlightMinute);
        }
        if (this.medianMinute) {
            console.log('this.medianMinute', this.medianMinute);
            this.medianValue = this.minutesToDateTime(Math.round(this.medianMinute));
        }

    },
    barColor: function(context, options) {
        //console.log('CHART barColor', context, this.highlightValue, context.raw.x);
        //context.dataIndex % 2 == 0
        let highlighted = this.highlightValue && this.highlightValue.toMillis() === context.raw.x.toMillis();
        if (highlighted) {
            return (this.highlightColor || this.$vuetify.theme.defaults.light.primary);
        }
        let isMedian = this.medianValue && this.medianValue.toMillis() === context.raw.x.toMillis();
        if (isMedian) {
            return (this.highlightColor || this.$vuetify.theme.defaults.light.primary);
        }
        return this.defaultColor;
        var color = options.color; // resolve the value of another scriptable option: 'red', 'blue' or 'green'
        return Chart.helpers.color(color).lighten(0.2);
    },
    minutesToDateTime(minutes) {
        return DateTime.now().startOf('day').plus({minutes: minutes });
    },
    createOrRefreshChart() {
        if (this.chart) {
            this.chart.update();
        }
        else {
            this.createChart();
        }
    },
    createChart() {
        if (this.chart) {
            return;
        }
        var ctx = this.$refs.chart;
        console.log('CHART creating', ctx, this.data);
        this.chart = new Chart(ctx, {
            type: 'bar',
            data: {
                datasets: [{
                    label: '# of finishers',
                    backgroundColor: this.barColor, // this.$vuetify.theme.defaults.light.primary,
                    data: Object.keys(this.data).map(x => ({x: this.minutesToDateTime(x), y: this.data[x]})),
                    borderWidth: 1
                }]
            },
            options: {
                maintainAspectRatio: false,
                scales: {
                    x: {
                        type: 'time',
                        time: {
                            unit: 'minute',
                            displayFormats: {
                                minute: "H'h' m'm'"
                            },
                            tooltipFormat: "'Finish time' H'h' m'm'",
                        },
                        ticks: {
                            display: !this.minimal,
                        },
                        grid: {
                            display: !this.minimal,
                        }

                    },
                    y: {
                        display: false,
                        beginAtZero: true,
                        ticks: {
                        }
                    }
                },
                plugins: {
                    legend: {
                        display: false,
                    }
                },
            }
        });
    }
  },
  watch: {
    data(val) {
       //this.createChart();
    },
    highlightMinute(val) {
        if (this.highlightMinute) {
            this.prepareData();
            this.createOrRefreshChart();
        }
    },
    mediantMinute(val) {
        if (this.mediantMinute) {
            this.prepareData();
            this.createOrRefreshChart();
        }
    },
  }
}
</script>