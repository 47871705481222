<template>
  <div>
      <v-simple-table v-if="mergedResults" fixed-header class="trainingplan-table">
        <template v-slot:default>
          <thead>
            <tr>
              <th>Week</th>
              <th v-for="(item, idx) in trainingPlan.weeks[0].workouts" :key="idx" class="text-left">
                <span v-if="item.day">
                  <span v-if="idx == 0 || item.day != trainingPlan.weeks[0].workouts[idx-1].day">Day {{ item.day }}</span>
                </span>
                <span v-else>Workout {{ idx+1}}</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(week, idx) in mergedResults.weeks">
              <tr :key="idx">
                <td :class="`${week.result ? 'light-green lighten-5' : ''} ${hasCommunityProgress(week) ? 'with-community-progress' : ''}`">
                  {{week.data.week}}
                  <v-icon v-if="week.result" small color="green">fa-check-double</v-icon>
                  <div v-if="week.data.is_rest" class="font-weight-bold">
                    Rest
                  </div>
                  <div v-if="week.data.is_peak" class="font-weight-bold">
                    Peak
                  </div>
                </td>
                <td v-for="(item, idx) in week.workouts" :key="item.data.id" :class="`pa-2 ${!item.result && item.schedule && item.schedule.isFuture && !item.schedule.isNow ? 'text-muted' : ''} ${getWorkoutColor(item)}  ${hasCommunityProgress(week) ? 'with-community-progress' : ''}`">
                  <div v-if="item.result || item.schedule" class="mb-2">
                    <span v-if="item.data.activity_type"><v-icon small color="grey" class="me-1">{{ $helpers.getActivityIcon(item.data.activity_type) }}</v-icon></span>
                    <span v-if="item.result">{{ item.result.reward_date | localDate('L') }}</span>
                    <span v-else-if="item.schedule" class="grey--text">{{ item.schedule.goal | localDate('L') }} </span>
                    <v-icon v-if="item.result" x-small color="green" class="ml-2">fa-check</v-icon>
                  </div>
                  <p>
                    {{ item.data.name }}
                    <TooltipDialog 
                      :title="item.data.name"
                      :text="item.badge && item.badge.description"
                      :icon="true"
                      :caption="detailsView ? 'Details' : ''"
                      class="px-0"
                      color="black"
                      small
                      :x-small="$vuetify.breakpoint.xs"
                      />
                  </p>
                  <div v-if="detailsView" class="mt-2">
                    <v-btn v-if="!item.result && item.schedule && (item.schedule.isNow||item.schedule.isPast)" small :x-small="$vuetify.breakpoint.xs" text @click="$emit('link', item)">
                      <v-icon small x-small class="mr-2">fal fa-link</v-icon>
                      Link
                    </v-btn>
                    <v-btn v-if="!item.result && item.schedule && (item.schedule.isNow||item.schedule.isPast)" small :x-small="$vuetify.breakpoint.xs" text :to="{name: 'activityEntry', query: { start: item.schedule.goal ? item.schedule.goal.toISODate() : null, duration: item.badge.activity_time_min ? (item.badge.activity_time_min+item.badge.activity_time_max)/2 : null, distance: item.badge.activity_distance_min ? (item.badge.activity_distance_min+item.badge.activity_distance_max)/2:null}}">
                      <v-icon small x-small class="mr-2">fal fa-plus</v-icon>
                      Add
                    </v-btn>
                    <v-btn v-if="item.result" small :x-small="$vuetify.breakpoint.xs" text class="" @click="$emit('unlink', item)">
                      <v-icon x-small class="mr-2">fal fa-unlink</v-icon>
                      Unlink
                    </v-btn>
                  </div>
                </td>
              </tr>
              <tr v-if="hasCommunityProgress(week)" :key="`${idx}-community`"> 
                <td :class="`${week.result ? 'light-green lighten-5' : ''} pa-2 greyZ lighten-5 text-muted community-progress`">
                  Community Progress
                </td>
                <td v-for="(item, idx) in week.workouts" :key="item.data.id" :class="`pa-2 greyZ lighten-5 text-muted community-progress ${getWorkoutColor(item)}`">
                  <span>
                    <v-icon small class="mr-2">fal fa-users</v-icon> Completed by {{ item.community ? item.community.total_count : 0 }} user(s).
                  </span>
                </td>
              </tr>
            </template>
            <tr v-if="visibleDays">
              <td colspan="10" class="grey lighten-4">
                <i>This is a summarized view. View the details of this training plan to show the full schedule.</i>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>      
      <v-skeleton-loader v-else type="table" />

  </div>
</template>

<script>
import Vue from "vue";
import store from "@/store";
import assetsService from "@/services/assetsService";
import eventService from "@/services/eventService";
import { DateTime, Interval } from 'luxon';
import { mapGetters } from "vuex";
import { EventBus } from '@/plugins/eventbus.js';
import TooltipDialog from "@/components/TooltipDialog";

export default {
  name: "TrainingPlanProgress",
  components: {
    TooltipDialog,
  },
  props: {
      trainingPlan: Object,
      event: Object,
      race: Object,
      meta: Object,
      result: Object,
      badges: Array,
      detailsView: Boolean,
      visibleDays: Number,
  },
  data() {
    return {
    };
  },
  async mounted() {
     //await this.prepareState();
  },
  async updated() {
      //await this.prepareState();
  },
  methods: {
    logState() {
        console.log('result:', this.result, 'plan:', this.trainingPlan, /*'meta:', this.meta,*/ 'badges:', this.badges, 'race:', this.race);
        return true;
    },
    hasCommunityProgress(week) {
        return week.workouts.some(x => x.community && x.community.total_count > 0);
    },
    getBadgeResult(id) {
        return this.result && this.result.badges && this.result.badges.find(x => x.id == id);
    },
    getBadge(id) {
        return this.badges && this.badges.find(x => x.id == id);
    },
    getWorkoutColor(item) {
        if (item.result) return 'light-green lighten-5'
        if (item.schedule && item.schedule.isNow) return 'amber lighten-3' 
        if (item.schedule && item.schedule.isPast) return 'amber lighten-5' 
        return '';
    },
    getBadgeCommunityProgress(id) {
        if (!this.meta || !this.meta.badges) { return null; }

        var badgeMeta = this.meta.badges.find(x => x.badge_id == id);
        if (badgeMeta == null) { return null;}
        return {
          total_count: badgeMeta.total_count,
        }
    },
    getSchedule(id) {
        if (!this.result || !this.badges) { return null; }

        const badge = this.badges.find(x => x.id == id);
        if (badge && badge.from_relative_days != null && badge.till_relative_days != null) {
            var start = DateTime.fromISO(this.result.started).plus({ days: badge.from_relative_days});
            var till = DateTime.fromISO(this.result.started).plus({ days: badge.till_relative_days});
            var midpoint = DateTime.fromISO(this.result.started).plus({ days: (badge.from_relative_days + badge.till_relative_days)/2});
            var now = DateTime.now();
            return {
                isFuture: till > now,
                isNow: now > start && now < till,
                isPast: now > till,
                start: start,
                daysFromNow: (midpoint < now ? Interval.fromDateTimes(midpoint, now) : Interval.fromDateTimes(now, midpoint)).length('days'),
                goal: midpoint,
                end: till,
            }; 
        }
        return {};
    },
  },
  computed: {
    mergedResults() {
        if (!this.trainingPlan || !this.badges) {
            return null;
        }
        return {
            weeks : this.trainingPlan.weeks.map(week => ({
                data: week,
                result: this.getBadgeResult(week.id),
                badge: this.getBadge(week.id),
                workouts: week.workouts.map(workout => ({
                    data: workout,
                    schedule: this.getSchedule(workout.id),
                    result: this.getBadgeResult(workout.id),
                    badge: this.getBadge(workout.id),
                    community: this.getBadgeCommunityProgress(workout.id),
                }))
            })).filter(x => !this.visibleDays || x.workouts.some(y => Math.abs(y.schedule.daysFromNow) < this.visibleDays)),
        };
    },
    
    ...mapGetters({
      user: "user"
    })
  },

};
</script>
<style lang="scss">
  .show-on-hover { display: none; }
  td:hover .show-on-hover { display: initial;}

  .trainingplan-table td { vertical-align: top;}

  /*.v-application .v-data-table tr td:hover { background-color: transparent !important;}*/
  .v-application .v-data-table tr td.with-community-progress { border-bottom-width: 0 !important; padding-bottom: 0 !important;}
  .v-application .v-data-table tr td.community-progress { border-bottom-color: #ccc !important; padding-top: 0 !important;}
</style>

